import React from 'react';
import styled from 'styled-components';
import { respondTo } from '../../util/breakpoints';
import * as colors from '../../util/colors';
import { arrowRightGreenDark, arrowRightGreenRollover } from '../../util/icons';
import { splitGradient } from '../../util/functions';
import DesktopImg from '../../assets/hero-banners/hero-image-desktop.png';
import MobileImg from '../../assets/hero-banners/hero-image-mobile.png';
import { DesktopOnly, MobileOnly, H6Style } from '../../util/typography';
import rccIcon from '../../assets/content-icons/indication-icons-rcc.png';
import hnsccIcon from '../../assets/content-icons/indication-icons-hnscc.png';
import arrowRight from '../../assets/color-icons/arrow-right.svg';
import { Link } from 'gatsby';

const CustomHeroContent = styled.section`
`;

const HeroImg = styled.img`
    width: 100%;
    max-width: 1032px;
    margin: 0 auto;
    display: block;
`;

const MegaMenu = styled.div`
    padding: 20px;
    background: ${colors.silver};
`;

const MegaMenuLinks = styled.div`
    margin: 0 auto;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    ${respondTo.md`
        max-width: 972px;
        flex-direction: row;
        gap: 18px;
    `}
`;

const MegaMenuLink = styled(Link)`
    padding: 15px 10px;
    background: ${colors.white};
    ${H6Style};
    font-weight: 700;
    color: ${colors.greenDark};
    font-size: 18px;
    display: flex;
    gap: 15px;
    align-items: center;
    text-decoration: none;
    border-radius: 6px;
    position: relative;
    padding-right: 25px;
    &:after {
        content: '';
        position: absolute;
        right: 15px;
        background: url(${arrowRightGreenDark});
        background-repeat: no-repeat;
        top: 50%;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
        background-position: center left;
        border-radius: 50%;
        background-size: 16px 16px, 200% 100%;
    }
    ${respondTo.md`
        gap: 10px;
        border-bottom: 0;
        flex: 1 0 calc(25% - 18px);
        &:after {
            content: '';
            position: absolute;
            right: 15px;
            background: url(${arrowRightGreenDark});
            background-repeat: no-repeat;
            top: 50%;
            transform: translateY(-50%);
            width: 16px;
            height: 16px;
            background-position: center left;
        }
        &:hover{
            color: ${colors.greenRollOver};
            ${respondTo.md`
                box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.35);
            `}
            &:after {
                background: url(${arrowRightGreenRollover});
                background-repeat: no-repeat;
                animation-duration: 0.3s;
                animation-name: arrowRight-animation;
                animation-fill-mode: both;
            }
        }
        @keyframes arrowRight-animation {
            0% {
                background-position: center 50%, left;
            }

            50% {
                background-position: calc(100% + 20px) center, center;
            }

            50.001% {
                background-position: calc(0% - 20px) center, center;
            }

            100% {
                background-position: center 50%, right;
            }
        }
    `}
`;

const MegaMenuIcon = styled.img`
    width: 50px;
`;

const megamenuLinks = [
    {
        title: 'Advanced Renal Cell Carcinoma',
        icon: rccIcon,
        to: '/advanced-renal-cell-carcinoma/'
    },
    {
        title: 'Advanced Endometrial Carcinoma',
        icon: hnsccIcon,
        to: '/endometrial-carcinoma/'
    },
];

const CustomHero = ({children, className}) => {

    return (
        <CustomHeroContent className={className}>
            <DesktopOnly><HeroImg src={DesktopImg} alt=''/></DesktopOnly>
            <MobileOnly><HeroImg src={MobileImg} alt=''/></MobileOnly>
            <MegaMenu>
                <MegaMenuLinks>
                    {megamenuLinks.map((megamenuLink) => (
                        <MegaMenuLink to={megamenuLink.to}>
                            <MegaMenuIcon src={megamenuLink.icon} alt='' />
                            {megamenuLink.title}
                        </MegaMenuLink>
                    ))}
                </MegaMenuLinks>
            </MegaMenu>
            {children}
        </CustomHeroContent>
)};
export default CustomHero;
